<template>
  <div :class="$style.porteflio">
    <img
      :class="$style.subtractIcon"
      loading="lazy"
      alt=""
      src="../assets/subtract.svg"
    />
    <main :class="$style.page">
      <section :class="$style.brevemente">
        <div :class="$style.frame">
          <div :class="$style.comingSoon">COMING SOON</div>
        </div>
        <div :class="$style.frame1">
          <div :class="$style.brevemente1">BREVEMENTE</div>
        </div>
      </section>
    </main>
    <footer :class="$style.footer">
        <div :class="$style.footerContent">
          <div :class="$style.footerColumns">
            <img
              :class="$style.clipPathGroup"
              loading="lazy"
              alt=""
              src="../assets/Clip path group mail.svg"
            />
            <img
              :class="$style.clipPathGroup1"
              loading="lazy"
              alt=""
              src="../assets/Clip path group phone.svg"
            />
          </div>
          <div :class="$style.hubdesignfocusgmailcom351Wrapper">
            <div :class="$style.hubdesignfocusgmailcom351Container">
              <p :class="$style.hubdesignfocusgmailcom">
                <a href="mailto:hubdesignfocus@gmail.com" :class="$style.link">hubdesignfocus@gmail.com</a>
              </p>
              <p :class="$style.hubdesignfocusgmailcom">
                <a href="tel:+351938404288" :class="$style.link" >+351 938404288</a>
              </p>
            </div>
          </div>
        </div>
        <div :class="$style.hubDesignFocus">Hub Design Focus</div>
      </footer>
  </div>
</template>

<script>
  import { defineComponent } from "vue";
  /*import RoundedButton from './RoundedButton.vue';*/

  export default defineComponent({
    name: "ComingSoon",
    /*components: {
      RoundedButton
    }*/
  });
</script>
<style module>
  .subtractIcon {
    margin-top: 10px;
    margin-left: -20px;
    width: 215.7px;
    height: 86.4px;
    position: static;
    image-rendering: crisp-edges;
  }
  .comingSoon {
    align-self: center;
    height: 100%;
    position: relative;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    overflow: visible;
    
  }
  .frame {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }
  .brevemente1 {
    align-self: center;
    position: relative;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .frame1 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    color: var(--pink);
  }
  .brevemente {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px ;
    padding-bottom: 50px;
    box-sizing: content-box;
    gap: 25px;
    max-width: 100%;
    text-align: center;
    font-size: var(--font-size-109xl);
    color: var(--lighter-blue);
    font-family: var(--font-anurati);
    overflow: visible;
    
  }
  .clipPathGroup {
    width: 25.7px;
    height: 20px;
    position: relative;
    object-fit: cover;
  }
  .clipPathGroup1 {
    width: 17.9px;
    height: 24px;
    position: relative;
    object-fit: cover;
  }
  .footerColumns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-mid);
  }
  .hubdesignfocusgmailcom {
    margin: 0;
  }
  .hubdesignfocusgmailcom351Container {
    position: relative;
    letter-spacing: 1px;
    line-height: 40px;
  }
  .hubdesignfocusgmailcom351Wrapper {
    width: 268px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footerContent {
    width: 318px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .hubDesignFocus {
    width: 555px;
    position: relative;
    font-size: 40px;
    letter-spacing: 0.25em;
    line-height: 40px;
    text-transform: uppercase;
    font-family: var(--font-anurati);
    color: var(--lighter-blue);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-shadow: 0px 4px 7.6px rgba(0, 0, 0, 0.25);
    max-width: 100%;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  .link {
  color: var(--black); /* Change this to your desired color */
  text-decoration: none; /* Remove underline */
  }

  .link:hover {
    color: var(--dark-blue); /* Change this to your desired hover color */
  }

  .link:active {
    color: var(--lighter-blue); /* Change this to your desired hover color */
    text-decoration: underline; /* Add underline on hover */
  }

  .footer {
    align-self: stretch;
    background-color: var(--pink);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-11xl) 59px var(--padding-11xl) 64px;
    box-sizing: border-box;
    max-width: 100%;
    gap: var(--gap-xl);
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--light-blue);
    font-family: var(--font-nirmala-ui);
  }
  .page {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    flex-shrink: 0;
  }
  .porteflio {
    height: 100vh;
    width: 100%;
    position: absolute;
    background: linear-gradient(
      180deg,
      #79d9d9,
      #a0e1e1 24.5%,
      #afe4e4 70%,
      #f2f2f2
    );
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 0px 0px;
    line-height: normal;
    letter-spacing: normal;
    min-height: 100%;
  }

  @media screen and (max-width: 1025px) {
    .footer {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media screen and (max-width: 975px) {
    .comingSoon {
      font-size: var(--font-size-32xl);
    }

    .brevemente1 {
      font-size: var(--font-size-32xl);
    }

    .hubDesignFocus {
      font-size: var(--font-size-13xl);
      line-height: 32px;
    }
    .footer { 
      justify-content: center;
    }
  }
  @media screen and (max-width: 725px) {
    .footer {
      padding-left: var(--padding-13xl);
      padding-right: var(--padding-13xl);
      box-sizing: border-box;
      justify-content: center
    }

    .porteflio {
      gap: 94px;
    }
  }
  @media screen and (max-width: 450px) {
    .comingSoon {
      font-size: var(--font-size-13xl);
    }

    .brevemente1 {
      font-size: var(--font-size-13xl);
    }

    .hubdesignfocusgmailcom351Container {
      font-size: var(--font-size-base);
      line-height: 32px;
    }

    .hubdesignfocusgmailcom351Wrapper {
      gap: 246px;
    }

    .hubDesignFocus {
      font-size: var(--font-size-5xl);
      line-height: 24px;
    }

    .porteflio {
      gap: 47px;
    }
  }
</style>